import react, { useEffect,useState } from "react";
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios'
const NavBar = ()=>{
  const [navtype, setnavtype] = useState(0)
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userTokenc = cookies.get('userToken')
const [avatat, setavatat] = useState("/logo.png")

 
  if(navtype == 0){

  // https://1.api.o4mr.tk/1128586260856139548879091.jpg
    return (
        <>

<div className="navbar mb-2 shadow-lg p-4 bg-neutral w-screen text-neutral-content rounded-box">
  <div className="flex-none flex">
  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none flex">
    <button className="btn btn-square  btn-ghost -mb-1">
    {/* <i className="fas fa-terminal text-xl -ml-3 "></i> */}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">           
        <path strokeLinecap="round" strokelinecapstrokelinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>               
      </svg>
    </button>
  </div> 

</div>


</div>
  </div> 
  <div className="flex-1 px-2 mx-2 ">
    <div className="flex">
{/*     <i className="fas fa-coffee inline-block text-xl mt- mr-3"> </i> */}
<div className="text-bold inline-block -ml-5 -mb-1 cursor-pointer	" onClick={() =>{navigate('/', { replace: false })}}>Skyex</div>

    </div>

  </div> 


  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
  {/* <div className="flex-none">
    <button className="btn btn-square btn-ghost">
    <i className="fas fa-coffee inline-block text-2xl mr-2"></i>
    </button>
  </div>  */}

</div>

</div>

  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
    <div className="flex-none">
    <div className="avatar">
      <div className="rounded-full w-14 h-14 m-1">
        <img alt='logo' src={avatat} />
      </div>
    </div>
  </div>
</div>
{/* avatat */}

</div>

</div>

        </>
    )}else if(navtype == 1){
      return (
        <>

<div className="navbar -mt-[0.8rem] mb-2 shadow-lg p-4 bg-neutral w-screen text-neutral-content rounded-box">












  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none flex">
    <button className="btn btn-square  btn-ghost -mb-1">
    {/* <i className="fas fa-terminal text-xl -ml-3 "></i> */}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">           
        <path strokeLinecap="round" strokelinecapstrokelinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>               
      </svg>
    </button>
  </div> 

</div>

</div>


  <div className="flex-1 px-2 mx-2 ">
    <div className="flex">
{/*     <i className="fas fa-coffee inline-block text-xl mt- mr-3"> </i> */}
  <div className="text-bold inline-block -ml-5 -mb-1 cursor-pointer	" onClick={() =>{navigate('/', { replace: false })}}>Skyex</div>

    </div>

  </div> 



  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none ">
    <button className="btn btn-square btn-ghost ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-7 h-7 stroke-current">     
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>                     
      </svg>
    </button>
  </div> 

</div>

</div>

  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
    <div className="flex-none">
    <div className="avatar">
      <div className="rounded-full w-14 h-14 m-1">
        <img alt='logo' src={avatat} />
      </div>
    </div>
  </div>
</div>

</div>

</div>

        </>
    )
    }
}
export default NavBar