import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import NavBar from "./com/navbar";
import Home from "./com/home";

// ReactGA.initialize('G-8SD1MX0ZMC');
// ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
  <div className="w-full text-white">
  <div className="bg-base-200 w-screen">
          <NavBar />
          </div>
          </div>
    <Routes>
      <Route path="/" element={<Home />}></Route>
    </Routes>
</>
  )
  
}

export default App;
