import react from "react";
import FootBar from "./footbar";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import "animate.css/animate.min.css";
import { Helmet } from "react-helmet";
import Darkfoot from "./darkfootbar";
const Home = ()=>{
  const navigate = useNavigate();
    return (
        <>
        <Helmet>
          <title>Skyex Landing</title>
        <meta
      name="description"
      content="SkyEX is a cloud web hosting software for everyone. Our web hosting services are built for the demanding requirements of website owners and professionals."
    />
        </Helmet>

        <div className="w-full scrollbar-hide text-white">










        <div className="hero  bg-[#11131d] -mt-[6.53rem] min-h-screen w-screen shadow-2xl" >
  <div className='-mt-12'></div> 
  <div className="text-center hero-content text-neutral-content ">
    <div className="max-w-md">
      <h1 className="mb-5 text-5xl leading-tight font-bold">
      Skyex Landing page
          </h1> 
      <p id='f1' className="mb-5">
Lost? Go back to the <a className="underline" href='https://skyex.me'>Home Page</a>
          </p> 
          

      
    </div>
    
  </div>

</div>
<div className="flex justify-center">
{/* <div className="animate-bounce bg-white dark:bg-gray-800 p-2 w-10 h-10 ring-1 ring-gray-900/5 dark:ring-gray-200/20 shadow-lg rounded-full -mt-32">
      <svg className="w-6 h-6 text-violet-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
      </svg>
    </div> */}
</div>


        </div>

<Darkfoot />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Home